(function ($, site, prodcat) {
  // Let product.skuSelect handle initiation
  $(document).on('product.init', '.js-product.js-product-full-v3, .js-product.js-product-v1', function () {
    var $self = $(this);
    var $imageContainer = $('.js-product-full__grid-image', $self);
    var $featureMediaObj = $();

    if ($imageContainer.length) {
      if (!$imageContainer.data().featureMediaLoaded) {
        $featureMediaObj = $imageContainer.find('.js-product-full__feature-media');
        $imageContainer.data('featureMediaObj', $featureMediaObj.html());
        $imageContainer.data('featureMediaLoaded', true);
      }
      $(document).trigger('product.gridImage.init', [$self]);
    }
  });

  // eslint-disable-next-line no-unused-vars
  $(document).on('product.skuSelect', '.js-product.js-product-full-v3, .js-product.js-product-v1', function (e, skuBaseId) {
    var productSkuBaseId = parseInt(skuBaseId);
    var $product = $(this);
    var $imageContainer = $('.js-product-full__grid-image', $product);
    var prod = $product ? prodcat.data.getProduct($product.data('product-id')) : null;
    var sku = prodcat.data.getSku(productSkuBaseId);
    var currentSku = parseInt($imageContainer.attr('data-sku-base-id'));
    var isProductV1 = $imageContainer.closest('.js-product-v1').length > 0;

    if (!$imageContainer.length) {
      return;
    }

    // Parse the SKU otherwise parse the defaultSku of the product or just the product image
    var data = sku ? sku : prod.defaultSku ? prod.defaultSku : prod;
    var $featureMediaObj = $('.js-product-full__feature-media', $product);
    var featureMedia = $imageContainer.data().featureMediaObj || $featureMediaObj.html();
    var siteName = Drupal?.settings?.common && Drupal?.settings?.common?.site_name + ', ' || '';
    var skuName = data['SHADENAME'] || data['PRODUCT_SIZE'] || '';
    var productName = prod['PROD_RGN_NAME'] + ', ' || '';
    var imageAltText = siteName + productName + skuName;

    data['imageAltText'] = imageAltText;

    // Feature media / first slide
    if (featureMedia) {
      data['product_featured_media'] = {
        html: featureMedia
      };
    }

    // Shaded IMAGE_LARGE array scrubbed to 2 images, non-shaded to 1.
    if (data.PARENT_CAT_ID === "CAT5817") {
      data['IMAGE_LARGE'] = data['IMAGE_MEDIUM'].slice(0, 2);
    } else {
      data['IMAGE_LARGE'] = data['IMAGE_MEDIUM'].slice(0, 1);
    }
    // Check if prod level images exist in sku data layer, and append if not. 
    if(!data['IMAGE_LARGE'].some(i=> prod['IMAGE_LARGE'].indexOf(i) >= 0)) {
      data['IMAGE_LARGE'] = data['IMAGE_LARGE'].concat(prod['IMAGE_LARGE']);
    }

    if (productSkuBaseId === currentSku && isProductV1 && $imageContainer.hasClass('product-full__grid-image-active')) {
      return;
    }

    if(!$imageContainer.hasClass('product-full__grid-image-active')) {
      $imageContainer.addClass('product-full__grid-image-active');
    }
    $imageContainer.attr('data-sku-base-id', productSkuBaseId);
    $imageContainer
      .fadeTo('slow', 0, 'linear', function () {
        var $self = $(this);
        // Sku Images
        var product_full_grid_image = site.template.get({
          name: 'product_full_grid_image_v1',
          data: data
        });

        $(this).html($(product_full_grid_image).html());

        // Refresh the slick slider if there is one
        $(document).trigger('product.gridImage.init', [$product]);

        // When Mantle video lazy load is disabled, reload video
        var $mantleMedia = $('.js-pdp-carousel__slide:not(.slick-cloned) .mantle-media-asset', $self);
        var $mantleVideo = $mantleMedia.find('video');
        if ($mantleMedia.data('loadBehavior') === 'non-lazy') {
          $mantleVideo.load();
          $mantleVideo.get(0).play();
        }
      })
      .fadeTo('fast', 1, 'linear');
  });

  Object.defineProperty(HTMLMediaElement.prototype, 'playing', {
    get: function () {
        return !!(this.currentTime > 0 && !this.paused && !this.ended && this.readyState > 2);
    }
  });

  $(document).on('click touchstart', '.js-product.js-product-full-v3, .js-product.js-product-v1', function () {
    var $self = $(this);
    var $mantleMedia = $('.js-pdp-carousel__slide:not(.slick-cloned) .mantle-media-asset', $self);
    var $mantleVideo = $mantleMedia.find('video');
    const videoElement = $mantleVideo[0];

    if (videoElement && videoElement.readyState >=2 && !videoElement.playing) { //2 indicates that the video element has loaded metadata
      // iOS video is not playing so play video now
      videoElement?.play();
    }
  });

  /**
   * Main handler for image carousels
   */
  $(document).on('product.gridImage.init', function (e, context = document) {
    $('.js-product-full__grid-image', context).each(function () {
      var $container = $(this);
      var $pContainer = $container.parents('.js-product-full-v3');
      var $pdpImages = $('.js-product-full__grid-image-carousel', $container);
      var $carousel = $('.js-pdp-carousel-v3', $pdpImages);
      var $dotsDiv = $('.carousel-dots', $container);
      var pdpSlideClassName = '.js-pdp-carousel__slide';

      if ($pContainer.length === 0) {
        $pContainer = $container.parents('.js-product-v1');
      }
      // Case for custom slides count and dots for mobile
      var settings = {
        slide: pdpSlideClassName,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        appendDots: $dotsDiv,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: "unslick"
          }
        ] 
      };

      var _checkDesktopView = function () {
        var cpgw = Unison.fetch.now();
        var pgw = Unison.fetch.all();

        return parseInt(cpgw.width, 10) >= parseInt(pgw.landscape, 10);
      };

      var _init_default_carousel = function () {
        var $carousels = $carousel.not('.slick-initialized');
        var $mCarousel = $pContainer.find($carousels);

        if ($carousels.length === 0) {
          return;
        }

        $carousel.on('init', function (event, slick) {
          var $videoSlide = $carousel.find('.js-pdp-carousel__slide--video:not(".slick-cloned")');
          var videoSlideIndex = $videoSlide.data('slick-index');
          var $slickDotsItems = $dotsDiv.find('.slick-dots li');

          if ($videoSlide.length) {
            $dotsDiv.find('.slick-dots li').eq(videoSlideIndex).addClass('slick-dot-video');
          } else {
            $slickDotsItems.addClass('slick-dot-item');
            if ($slickDotsItems.length === 2) {
              $slickDotsItems.addClass('two-slides-carousel');
            } else if ($slickDotsItems.Length === 4) {
              $slickDotsItems.addClass('four-slides-carousel');
            }
            $dotsDiv.find('.slick-dots button').addClass('slick-dot-button');
          }
        });

        // Init this carousel with our settings
        $carousels.slick(settings);

        $(window).on('resize', function() {
          if (!_checkDesktopView() && !$carousel.hasClass('slick-initialized')) {
            $carousels.slick(settings);
          }
        })
      };

      _init_default_carousel();

    });
  });
})(jQuery, window.site || {}, window.prodcat || {});
